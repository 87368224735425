import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Ranitidine(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div>
                <h2>What Is Ranitidine and How Is It Used?</h2>
                <p>
                    Ranitidine, the key active compound in Zantac, which also
                    exists in generic forms, has been a highly sought-after
                    medication since its introduction in the 1980s. Zantac
                    gained popularity for its effectiveness in alleviating
                    symptoms like upset stomach and heartburn, typically
                    associated with acid indigestion. Available in both
                    over-the-counter and prescription strengths, this medication
                    catered to a wide range of needs. Prescription-strength
                    Zantac and its generic counterparts, ranitidine, were
                    specifically endorsed for treating gastroesophageal acid
                    reflux disease (GERD), a condition known for causing
                    heartburn. Additionally, these medications received approval
                    for the prevention of ulcers in the intestines, further
                    broadening their therapeutic scope.
                </p>
            </div>
        </div>
    );
}
